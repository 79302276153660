const documentClickHandlers = [
  {
    selector: '.nav-burger-toggle:not(nav-burger-toggle--close)',
    handler() {
      document.querySelector('.nav-burger').classList.add('is-open');
    },
  },
  {
    selector: '.nav-burger-toggle--close',
    handler() {
      document.querySelector('.nav-burger').classList.remove('is-open');
    },
  },
  {
    selector: '.rwl-tabs-nav__item',
    handler(tab) {
      tab
        .closest('.rwl-tabs')
        .querySelector('.rwl-tabs-item')
        .classList.remove('is-active');
      for (const sibling of tab.parentNode.children) {
        sibling.classList.remove('is-active');
      }
      tab.classList.add('is-active');
      const targetTab = document.querySelector(tab.dataset.targetTab);
      if (targetTab === null) {
        return;
      }
      for (const siblingTabs of targetTab.parentNode.children) {
        siblingTabs.classList.remove('is-active');
      }
      targetTab.classList.add('is-active');
    },
  },
  {
    selector: '.solutions__tabs .tabs__toggle',
    handler(tab) {
      tab
        .closest('.tabs')
        .querySelector('.solutions-tab')
        .classList.remove('is-active');
      for (const sibling of tab.parentNode.children) {
        sibling.classList.remove('is-selected');
      }
      tab.classList.add('is-selected');
      const targetTab = document.querySelector(tab.dataset.targetTab);
      if (targetTab === null) {
        return;
      }
      for (const siblingTabs of targetTab.parentNode.children) {
        siblingTabs.classList.remove('is-active');
      }
      targetTab.classList.add('is-active');
    },
  },
  {
    selector: '.accordion-item__toggle',
    handler(element) {
      element.classList.toggle('is-open');
    },
  },
  {
    selector: '.faq-item__toggle',
    handler(element) {
      element.closest('.faq-item').classList.toggle('is-open');
    },
  },
  {
    selector: '.form-checkbox__fauxbox span',
    handler(element) {
      element.parentNode.querySelector('[type="checkbox"]').click();
    },
  },
  {
    selector: '[data-close-flash]',
    handler(closeButton) {
      const flashType = closeButton.dataset['close-flash'];
      const flashMessage = closeButton.closest('.auth-flash-msg');

      flashMessage.classList.add('hide');
      flashMessage.classList.remove('auth-flash-msg--' + flashType);
      flashMessage.querySelector('span').innerHTML = '';
    },
  },
  {
    selector: '.liquidity-intro-tabs button:not(is-active)',
    handler(tabButton) {
      const tabName = tabButton.dataset.tab;
      const tabsList = document.querySelectorAll(
        '.liquidity-intro-tabs button, .liquidity-intro-tabs__item',
      );

      for (const tab of tabsList) {
        tab.classList.remove('is-active');
      }

      tabButton.classList.add('is-active');
      document
        .querySelector(
          '.liquidity-intro-tabs__item[data-tab="' + tabName + '"]',
        )
        .classList.add('is-active');
    },
  },
];

document.addEventListener('DOMContentLoaded', () => {
  document.addEventListener('click', (event) => {
    const element = event.target;
    for (const clickHandler of documentClickHandlers) {
      const target = element.closest(clickHandler.selector);
      if (target === null) {
        continue;
      }
      event.preventDefault();
      clickHandler.handler(target);
    }
  });
});
